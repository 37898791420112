<template lang="html">
    <main-layout>
        <template #content>
            <section class="authentication authentication-container">
                <div class="authentication__form">
                    <div class="forgot-section form-block flip-card-forgot flip-card-forgot_back">
                        <div class="form-block__header">
                            <h2 class="form-block__title">
                                Recover password
                            </h2>
                        </div>

                        <form
                            class="form-block__body"
                            @submit.prevent="validate"
                        >
                            <p class="form-block__description">
                                We will send instructions on how to reset your password to your account's e-mail address:
                            </p>
                            <form-input
                                v-model.trim="emailValue"
                                placeholder="Email address"
                                prepend-icon="EmailOutlineIcon"
                                type="text"
                                name="email"
                                :field.sync="emailValue"
                                :errors="validationMessage($v.email)"
                                :is-valid="$v.email.$dirty && !$v.email.$anyError"
                                @input.native="$v.email.$touch()"
                                @blur.native="$v.email.$touch()"
                            />
                            <div class="form-block__footer mt-4">
                                Back to
                                <router-link
                                    class="colored-text"
                                    :to="{ name: 'login', params: {} }"
                                >
                                    Login
                                </router-link>
                                or
                                <router-link
                                    class="colored-text"
                                    :to="{ name: 'register', params: {} }"
                                >
                                    Register an Account
                                </router-link>
                            </div>
                            <button class="btn-base btn-main button-width-100">
                                <template v-if="loading">
                                    <button-loader />
                                </template>
                                <span v-else>Send</span>
                            </button>
                        </form>
                    </div>
                </div>
            </section>
        </template>
    </main-layout>
</template>

<script>
// vuelidate
import { validationMixin } from 'vuelidate'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/forgot-password/Rules'
import { formMessages } from '@/validation/forgot-password/Messages'

// elements
import MainLayout from '@/layouts/MainLayout';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import ButtonLoader from '@/components/common/ButtonLoader.vue';

// vuex
import {
    ACTION_WRITER_RESET_PASSWORD_SEND_EMAIL
} from '@/store/modules/writer/action-types';
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

const { mapActions: mapWriterActions } = createNamespacedHelpers('writer');

export default {
    components: {
        MainLayout,
        FormInput,
        ButtonLoader
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data: () => ({
        email: '',
        loading: false,
        emailValue: ''
    }),
    watch: {
        emailValue(newValue) {
            this.email = newValue.trim()
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapWriterActions([
            ACTION_WRITER_RESET_PASSWORD_SEND_EMAIL
        ]),
        async submit() {
            try {
                this.loading = true
                await this[ACTION_WRITER_RESET_PASSWORD_SEND_EMAIL]({
                    email: this.email
                })
                eventBus.$emit('showSnackBar', 'Please check your e-mail for instructions.', 'success');
                await this.$router.push({ name: 'login' })
            } catch (e) {
                eventBus.$emit('showSnackBar', e, 'error');
                console.error(e)
            } finally {
                this.loading = false
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.forgot-section {
    .form-block {
        &__footer {
            margin-bottom: 30px;
        }
    }
    .form-input__fieldset {
        margin-bottom: 30px;
    }
    .form-block__description {
        margin-bottom: 25px;
    }
}
</style>
